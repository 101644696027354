

























































import type { Picture } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'

interface Tooltip {
  title?: string
  text: string
  picture?: Picture
}

export default defineComponent({
  name: 'FormTooltip',
  props: {
    content: {
      type: Object as PropType<Tooltip>,
      required: true,
    },
    displayOnSide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isOpen = ref(false)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const open = () => {
      isOpen.value = true

      if (props.displayOnSide) {
        document.body.classList.add('no-scroll')
      }
    }

    const close = () => {
      isOpen.value = false
      document.body.classList.remove('no-scroll')
    }

    return {
      isOpen,
      toggle,
      open,
      close,
    }
  },
})
